.App-project-item-card,
.App-project-item-card .ant-card-actions {
    background: linear-gradient(111.6deg, #E7EFFB -0.38%, #ECF9F1 100%), #F2F2F2;
}

.App-project-item-card .ant-card-body {
    padding-top: 4px;
}

.App-project-item-card .ant-card-body .ant-card-meta-title {
    font-size: 14px;
}

.App-project-item-card .ant-card-head {
    border-bottom: none;
    color: rgba(0, 0, 0, 0.9);
    word-break: break-word;
    padding-top: 14px;
}

.App-project-item-card .ant-card-actions {
    border-top-color: rgba(0, 0, 0, 0.05);
}

.App-project-item-card .ant-card-actions > li {
    color: rgba(0, 0, 0, 0.7);
}

.App-project-item-card .ant-card-head-title {
    min-width: 80px;
}

.App-project-item-card .ant-card-head-title .App-project-item-card_title {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding-right: 26px;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: normal;
}

.App-project-item-card .ant-card-meta-title p {
    margin: 0;
}

.App-project-item-card__log-button {
    color: #1890ff;
    background-color: #b3d8ff38;
    border-color: #b3d8ff;
}

.App-project-item-card__ribbon {
    top: 6px
}