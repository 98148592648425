.App-log__project-loading {
    margin-top: 20%;
    margin-left: 45%;
}

.App-log__list-loading {
    margin-top: 10%;
    margin-left: 45%;
}

.App-log .ant-page-header-heading-title {
    color: var(--ts-blue);
}

.App-log .ant-page-header {
    padding: 0;
}

.App-log__time-spent {
    width: 140px;
}

.App-log_period-wrapper {
    align-items: center;
}

.App-log__task-option-tag {
    margin-left: -2px;
}

.App-log_detail {
    font-weight: bold;
}

.App-log__load-more {
    text-align: center;
    margin: 20px 0;
}

.App-log__date-period {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}