.App-login__container {
    display: flex;
    align-items: center;
	margin-right: 26px;
}

.App-login__avatar {
    width: 52px;
    height: 52px;
    line-height: 52px;
    font-size: 24px;
}

.App-login__avatar:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.App-login__menu {
    background-color: #fafafa;
    min-width: 240px;
    padding: 8px 0;
}

.App-login__user-wrapper {
    display: flex;
    align-items: center;
    padding: 4px 0;
}

.App-login__user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.App-login__user-info-name {
    color:rgba(0, 0, 0, .8);
    font-size: 18px;
}

.App-login__user-info-email {
    color:rgba(0, 0, 0, .4);
    font-size: 14px;
}

.App-login__user-avatar,
.App-login__avatar {
    background-color: #f56a00;
}

.App-login__logout {
    display: flex;
    align-items: center;
    font-size: 20px;
    opacity: 0.7;
    padding: 4px 0;
}

.App-login__logout-label {
    font-size: 18px;
}

.App-login__logout-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    width: 40px;
    font-size: 26px;
}