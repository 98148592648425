.App-reports__table {
    user-select: none;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content .label {
    margin-right: 5px;
}

.content .value {
    font-weight: 700;
    font-size: 16px;
}

.custom-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-tab .label {
    font-weight: bold;
    font-size: 16px;
}

.custom-tab .time {
    opacity: 0.4;
}

.description .value {
    font-weight: bold;
}

.noproject {
    font-size: 30px;
    font-weight: bold;
    opacity: 0.7;
    margin: 50px auto;
}

.project-card {
    margin-bottom: 20px;
}

.card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-name {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.user-name {
    font-size: 12px;
    margin-top: 6px;
}

.no-data {
    display: flex;
    flex-direction: column;
    width: 150px;
    align-items: center;
}

.loading {
    display: block;
    margin: auto;
    padding: 50px;
}

.App-reports_status-active,
.App-reports_status-inactive {
    border: 1px solid;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    line-height: 26px;
    padding: 0 6px;
}

.App-reports_status-active {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
}

.App-reports_status-inactive {
    border-color: #ffbb96;
    color: #d4380d;
    background-color: #fff2e8;
}

.App-projects-table .ant-table-thead {
    font-size: 18px;
}

.App-projects-filter-input {
    max-width:  300px;
    margin-bottom: 8px;
    background-color:#fafafa;
    border-radius: 6px;
}

.App-projects-filter-input input {
    background-color: #fafafa;
}

.App-projects-filter-input .ant-input-clear-icon {
    font-size: 16px;
}

.App-projects-filter-input .ant-input-prefix {
    font-size: 18px;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
    background: linear-gradient(111.6deg, #E7EFFB -0.38%, #ECF9F1 100%), #F2F2F2;
    padding: 0;
}

.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background: #e6f7ff;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
    background: #fafafa;
}