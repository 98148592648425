.App-users-table .ant-table-thead {
    font-size: 18px;
}

.App-users__user-wrapper {
    display: flex;
    align-items: center;
}

.App-users__userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.App-users__userInfo-name {
    color:rgba(0, 0, 0, .87);
    font-size: 18px;
}

.App-users__userInfo-email {
    color:rgba(0, 0, 0, .4);
    font-size: 12px;
}

.App-users__role {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.App-users__role:hover {
    cursor: pointer;
}

.App-users__role-setting {
    margin-left: 8px;
}

.App-users__modal {
    top: 30%;
}

.App-users__modal .ant-modal-content,
.App-users__modal .ant-modal-header {
    border-radius: 6px;
}

.App-users__modal button {
    border-radius: 6px;
}

.App-users-filter-input {
    max-width:  300px;
    margin-bottom: 8px;
    background-color: #fafafa;
    border-radius: 6px;
}

.App-users-filter-input input {
    background-color: #fafafa;
}

.App-users-filter-input .ant-input-clear-icon {
    font-size: 16px;
}

.App-users-filter-input .ant-input-prefix {
    font-size: 18px;
}
