.assign-user-modal__body {
    display: flex;
}

.App-user-modal {
    justify-content: center;
    width: 800px !important;
}

.App-user-modal .ant-transfer-list-header {
    display: none;
}

.App-user-modal__transfer-email {
    color: grey;
    margin-left: 10px;
}