.App-header-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    height: 90px;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 2px auto;
    border-radius: 12px;
}

.App-header-logo {
    position: absolute;
    left: 26px;
    color: var(--ts-blue);
    font-size: 50px;
}

.App-header-title {
    font-size: 32px;
    margin-left: 100px;
    color: var(--ts-blue);
    font-weight: bold;
}

.App-header-profile {
    position: absolute;
    right: 0;
}

