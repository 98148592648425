.App-reports__loading {
    position: absolute;
    left: 48%;
    top: 30%;
}

.App-reports__no-data {
    margin: 0 auto;
}

.App-reports__statistic-title {
    font-size: 20px;
    font-weight: bold;
}

.App-reports__statistic-item {
    margin-top: 10px;
    margin-bottom: 36px;
}

.App-reports__period-header {
    font-weight: bold;
    font-size: 20px;
}

.App-reports__row-hour {
    font-size: 18px;
    padding-left: 2px;
}

.App-reports__wrapper .ant-collapse-arrow {
    padding: 0 !important;
    font-size: 18px !important;
}

.App-reports__wrapper .ant-list-item-meta-description {
    color: #000;
    font-size: 16px;
}

.App-reports__title {
    font-size: 26px;
    font-weight: bold;
    color: var(--ts-blue);
    margin-bottom: -10px;
}

.App-reports__collapse.ant-collapse .ant-collapse-header {
    display: flex;
    align-items: center;
}

.App-reports__collapse.ant-collapse .ant-row {
    width: 100%;
}

.App-reports__collapse .ant-collapse-content-box {
    padding-left: 46px;
}

.App-reports__date-period {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.App-reports__task-option-tag {
    margin-left: -2px;
}

.App-reports__detail-item {
    align-items: center;
    display: flex;
    width: 100%;
}

.App-reports__detail-item-label {
    margin-right: 4px;
}

.App-reports__expand-btn,
.App-reports__collapse-btn {
    margin-bottom: 4px;
}