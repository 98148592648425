@import '~antd/dist/antd.css';
@import './antd.css';

html {
	--ts-blue: #1A48E9;
}

body {
	background: linear-gradient(111.6deg, #E7EFFB -0.38%, #ECF9F1 100%), #F2F2F2;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.App-body {
	display: flex;
	flex: 1;
	padding: 20px;
}

.App-body__wrapper {
	flex: 1;
	margin-left: 20px;
	overflow: hidden;
}

.App-body__wrapper-content {
	position: relative;
	background-color: rgba(255, 255, 255, 0.6);
	height: 100%;
	padding: 24px 24px 10px 24px;
	overflow: auto;
	border-radius: 12px;
}

.App-loading  {
	margin-top: 50px;
}

.App-nologin {
	font-size: 24px;
	text-align: center;
	margin: 50px auto;
	font-weight: bold;
}

.App-auth-checking {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
}

.App-auth-checking .ant-spin-text {
	margin-left: 20px;
	font-size: 30px;
}

.App-message_invalid-email {
	position: absolute;
	top: 20px;
	right: 20px;
}