.App-projects .ant-row .ant-col .ant-card {
    box-shadow: 0 0 6px rgb(0, 0, 0, .125);
}

.App-projects .ant-row .ant-col .ant-card:hover {
    border-color: rgb(24,144,255, .125);
    box-shadow: 0 1px 6px -2px rgb(24 144 255 / 16%),
                0 3px 6px 0 rgb(24 144 255 / 12%),
                0 5px 12px 4px rgb(24 144 255 / 9%);
}

.project-item__card-action:hover {
    position: relative;
}

.App-projects__table {
    user-select: none;
}

.App-projects__action-log,
.App-projects__action-more {
    color: #1890ff;
    background-color: #b3d8ff38;
    border-color: #b3d8ff;
    border: 1px solid;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 2px 6px;
    font-size: 14px;
}

.App-projects__action-log {
    margin-left: -6px;
}

.App-projects__action-menu {
    width: 160px;
    margin-top: 6px;
    border-radius: 6px;
    background-color: #fafafa;
}

.App-projects__action-menu .ant-dropdown-menu-title-content {
    color: #1890ff;
    font-size: 14px;
}

.ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
}

.App-projects__action-status,
.App-projects__action-add,
.App-projects__action-edit {
    color: #1890ff;
    font-size: 20px;
    margin-right: 8px;
}

.project-item__card-action::after {
    content: '';
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: 0px;
    right: 0px;
}

.App-projects__description-label {
    display: -webkit-box;
    max-width: 350px;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.App-projects__description .ant-tooltip-inner {
   color: #1890ff;
   background: linear-gradient(111.6deg, #E7EFFB -0.38%, #ECF9F1 100%), #F2F2F2;
   max-width: 500px;
   max-height: 296px;
   overflow: hidden;
   text-overflow: ellipsis;
   word-break: break-all;
   display: block;
}

.App-projects .ant-page-header {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

.App-projects .ant-page-header-heading-title {
    color: #1890ff;
    font-size: 30px;
}

.App-projects_status-active,
.App-projects_status-inactive {
    border: 1px solid;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-block;
    line-height: 26px;
    padding: 0 6px;
}

.App-projects_status-active {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
}

.App-projects_status-inactive {
    border-color: #ffbb96;
    color: #d4380d;
    background-color: #fff2e8;
}

.App-projects__create-project {
    border-radius: 4px;
}

.App-projects__tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    background-color: #b3d8ff38;
}

.App-projects__pagination {
    margin-top: 20px;
    margin-right: 6px;
}
