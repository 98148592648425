.App-home {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(111.6deg, #E7EFFB -0.38%, #ECF9F1 100%), #F2F2F2;
}

.App-home-content {
    display: flex;
    flex-direction: column;
    height: max-content;
    padding: 0 120px;
    margin-top: 6%;
}

.App-home-content__title {
    font-weight: bold;
    font-size: 80px;
    color: var(--ts-blue);
    line-height: 115px;
}

.App-home-content__subtitle {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    height: 43px;
}

.App-home-content__start {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 80px;
    margin-top: 20px;
    padding: 24px;
    box-sizing: border-box;
    background-color: var(--ts-blue);
    color: #fff;
    border-radius: 12px;
    font-size: 24px;
    font-weight: bold;
}

.App-home-content__start:hover {
    cursor: pointer;
    opacity: 0.9;
}
